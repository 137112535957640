<template>
  <div id="app">
    <header-component />
    <router-view v-if="isMounted" />
  </div>
</template>

<script>
  import header from './components/header.vue';

  import apiService from './api/api.service.js';
  import auth from './authConfig.js';

  export default {
    data() {
      return {
        isMounted: false,
      }
    },
    async created() {

      const msalAppInstance = await auth.init();

      msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {

        if (!tokenRes) {
          if (!auth.user()) {
            auth.login();
          } else {
            await this.fetchData();
          }
        } else {
          await this.fetchData();
        }
      });
    },
    components: {
      'header-component': header
    },
    methods: {
      async fetchData() {
        try {
         
          let tokenResponse = await auth.acquireTokenResponse(); // Get Auth Token
          if (!tokenResponse) throw new Error("Token response is null");

          const azureId = localStorage.getItem('azure_id');
          if (!azureId) {
            console.error("No azure_id found in local storage");
            return;
          }

          const response = await apiService.GetRoles(azureId, tokenResponse.accessToken);

          const data = response.data;
          if (data && data.issuccess) {
            localStorage.setItem('user_roles', data.data);
          } else {
            console.error("No valid data received from API");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        this.isMounted = true;
      },
    },
  }
</script>

<style scoped>
  @import '../src/assets/css/cgslight.css';
  @import '../src/assets/css/site.css';

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  nav {
    padding: 30px;
  }

    nav a {
      font-weight: bold;
      color: #2c3e50;
    }

      nav a.router-link-exact-active {
        color: #42b983;
      }

  header-component {
    flex-shrink: 0;
  }

  router-view {
    flex-grow: 1;
    overflow: auto;
  }
</style>
