<template>
  <div class="header" key="card">
    <div class="notes__header">
      <img src="../assets/swift_logo_ai_v2.png" alt="Swift" />
      <div>
        <router-link to="/" class="router-link">Command Center</router-link>
        <router-link to="/Processing" class="router-link">Pipeline</router-link>
        <router-link to="/NotificationManagement" class="router-link hide-on-mobile">Notification Management</router-link>
        <router-link v-if="hasPostCloseRole" to="/PostCloseConnect" class="router-link hide-on-mobile">Post Close Connect</router-link>
      </div>
    </div>
    <div class="signout-btn-box">
      <button class="signout-btn" @click="deepLogout">Sign out</button>
    </div>
  </div>
</template>

<script>
  import apiService from '../api/api.service.js';
  import auth from '../authConfig.js';

  export default {
    data() {
      return {
        userRoles: [],
      }
    },
    mounted() {
      this.userHasCorpRole();      
    },
    methods: {
      deepLogout() {
        auth.logout();
      },
      userHasCorpRole() {

        let userRolesFromLocalStorage = localStorage.getItem('user_roles');
        try {
          if (userRolesFromLocalStorage) {
            this.userRoles = userRolesFromLocalStorage.split(',');            
          } else {
            this.userRoles = [];
          }
        } catch (error) {
          console.warn("Failed to parse user_roles as JSON, using it as a string.");
        }
        this.hasPostCloseRole();
      },
      hasPostCloseRole() {
        if (this.userRoles.includes("Post Closer")) {
          return true;
        }
        else {
          return false
        }
      }

    },
  }
</script>

<style>
  .notes__header {
    display: flex;
    align-items: flex-end;
  }

  .header {
    display: flex;
    border-bottom: 2px solid var(--accent);
    justify-content: space-between;
    padding: 20px 10px;
  }

    .header img {
      height: 40px
    }

    .header .router-link {
      text-decoration: none;
      margin-bottom: 3px;
      margin-left: 30px;
      color: var(--primary);
      font-size: 1.8rem;
      min-width: 85px;
    }

      .header .router-link:hover {
        color: var(--text-secondary-on-background);
      }

      .header .router-link.router-link-active {
        color: var(--accent);
        font-weight: 600;
      }

  .signout-btn-box {
    display: flex;
    align-items: center;
  }

  .signout-btn {
    display: block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background: none;
    padding: .7rem 1rem;
    margin-left: auto;
    border: none;
  }

    .signout-btn:hover {
      cursor: pointer;
      background: var(--accent-ltr);
      border-radius: 1rem;
    }

  @media (max-width: 600px) {
    .hide-on-mobile {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .header .router-link {
      font-size: .8rem;
      margin-left: 10px;
      min-width: 60px;
    }

    .signout-btn {
      padding: 0;
    }
  }

  @media (max-width: 375px) {
    .notes__header {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
