import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotificationManagementView from '../views/NotificationManagementView'
import DashboardView from '../views/DashboardView.vue'
import PostCloseConnect from '../views/PostCloseConnect.vue'

const routes = [
  {
    path: '/processing',
    name: 'processing',
    query: { id: '' },
    component: HomeView
  },
  {
   path: '/NotificationManagement',
   name: 'notificationManagement',
   component: NotificationManagementView
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/PostCloseConnect',
    name: 'postCloseConnect',
    component: PostCloseConnect
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
