<template>
    <div class="subscription-management">
        <h2 class="tab-title">Select A Notification Type</h2>
        <div class="actions-container">
            <k-dropdown class="select-type-dropdown"
                        :data-items="subscriptionTypeSelects"
                        v-model="selectedSubscriptionType"></k-dropdown>
        </div>
        <div class="pipeline-container">
            <k-grid :data-items="filteredItems"
                    :data-item-key="'id'"
                    :columns="pipelineColumns"
                    :column-menu="true"
                    :sortable="true"
                    :pageable="gridPageable"
                    :filterable="true" 
                    :sort="sort"
                    :filter="filter"
                    :take="take"
                    :total="total"
                    :skip="skip"
                    :selectable="true"
                    :selected-field="selectedField"
                    @datastatechange="dataStateChange"
                    @rowclick="onRowClick"
                    @selectionchange="onNotiSelectionChange"
                    @headerselectionchange="onHeaderSelectionChange"
                    @filterchange="onFilterChange"
                    @sortchange="onSortChange">
                    <k-grid-toolbar>
                        <h2 class="pipeline-title"><i class="fa-light fa-envelopes"></i> Subscription Settings</h2>

                        <span class="k-textbox k-grid-search k-display-flex">
                            <k-input :style="{ width: '300px' }"
                                    placeholder="Search in all columns..."
                                    :value="searchWord"
                                    :icon-name="'search'"
                                    @input="onSearchChange"></k-input>
                        </span>
                        <k-button class="subscribe-selected-btn" @click="onSubscribeSelectedNotis" :disabled="subscribeSelectedBtnDisabled">Subscribe Selected</k-button>
                        <k-button class="unsubscribe-selected-btn" @click="onUnsubscribeSelectedNotis" :disabled="unsubscribeSelectedBtnDisabled">Unsubscribe Selected</k-button>
                        <k-button v-if="selectedNotificationType === 'email'" class="subscribe-all-btn" @click="onSubscribeAllNotis" :disabled="subscribeAllBtnDisabled">Subscribe All</k-button>
                        <k-button v-if="selectedNotificationType === 'email'" class="unsubscribe-all-btn" @click="onUnsubscribeAllNotis" :disabled="unsubscribeAllBtnDisabled">Unsubscribe All</k-button>
                    </k-grid-toolbar>
            </k-grid>
        </div>

        <edit-subscription-dialog :show-dialog="showEditSubscriptionDialog"
                                  :notification="selectedNotification"
                                  :unsubscribed-from-all-emails="unsubscribedFromAllEmailNotis"
                                  :unsubscribed-from-all-emails-item="unsubscribedFromAllEmailItem"
                                  :subscribed-to-all-sms="subscribedToAllSMS"
                                  :subscribed-to-all-sms-item="subscribedToAllSMSItem"
                                  :subscribed-to-all-push="subscribedToAllPush"
                                  :subscribed-to-all-push-item="subscribedToAllPushItem"
                                  :all-notifications="getAllNotificationsByType()"
                                  :azure-id="azureId"
                                  :current-subscription-status="getCurrentSubscriptionStatus()"
                                  :notification-type="selectedNotificationType"
                                  :notification-subscriptions="getNotificationSubscriptions()"
                                  @dialogclosed="toggleEditSubscriptionDialog"
                                  @subscriptionsupdated="onSubscriptionsUpdated"></edit-subscription-dialog>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="confirmFunction"></confirm-dialog>

        <div class="component__loader-container" v-if="loading">
            <k-loader size="large" type="infinite-spinner"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default__notification-container">
            <k-fade :appear="successNotification">
                <k-notification v-if="successNotification"
                                :type="{ style: 'success', icon: true }"
                                :closable="true"
                                @close="onCloseNotification('successNotification')">
                    <span>{{ notificationMsg }}</span>
                </k-notification>
            </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
    import { Input } from '@progress/kendo-vue-inputs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";

    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import confirmDialog from '../components/confirmDialog.vue';
    import editSubscriptionDialog from './editSubscriptionDialog.vue';

    import cloneDeep from 'lodash.clonedeep';

    import apiService from '../api/api.service.js';
    import auth from '../authConfig.js';

    import subscriptionHelpers from '../helpers/subscriptionHelpers.js';

    export default {
        name: 'SubscriptionManagement',
        data() {
            return {
                allNotifications: [],
                azureId: null,
                confirmFunction: null,
                confirmMsg: '',
                emailSubscriptions: [],
                errorMsg: '',
                errorTitle: '',
                filter: null,
                filteredItems: [],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                loading: true,
                notificationMsg: '',
                pushSubscriptions: [],
                searchWord: '',
                selectedField: 'selected',
                selectedNotification: null,
                selectedNotificationId: null,
                selectedNotiRows: [],
                selectedSubscriptionType: 'Email Notifications',
                showConfirmDialog: false,
                showEditSubscriptionDialog: false,
                showErrorDialog: false,
                skip: 0,
                smsSubscriptions: [],
                sort: [],
                subscriptionTypeSelects: ['Email Notifications', 'SMS Notifications', 'Push Notifications'],
                successNotification: false,
                take: 50,
                total: 0
            }
        },
        computed: {
            areAllNotificationsSelected() {
                return this.selectedNotiRows.length === this.filteredItems.length && this.selectedNotiRows.length > 0;
            },
            emailNotifications() {
                return this.allNotifications ? this.allNotifications.filter(noti => noti.html) : [];
            },
            pipelineColumns() {
                let columns = [
                    { field: 'selected', width: '45px', columnMenu: false, filterable: false, headerSelectionValue: this.areAllNotificationsSelected},
                    { field: 'category', title: 'Category', width: '150' }
                ]

                if (this.selectedSubscriptionType === 'Email Notifications') {
                    columns = [...columns, ...[
                        { field: 'subscriptionStatus', title: 'Subscription Status', width: '200' },
                        { field: 'title', title: 'Title' },
                        { field: 'subject', title: 'Notification Subject' }
                    ]];
                }

                if (this.selectedSubscriptionType === 'SMS Notifications') {
                    columns = [...columns, ...[
                        { field: 'smsSubscriptionStatus', title: 'Subscription Status', width: '200' },
                        { field: 'title', title: 'Title' },
                        { field: 'sms_message', title: 'SMS Message' }
                    ]];
                }

                if (this.selectedSubscriptionType === 'Push Notifications') {
                    columns = [...columns, ...[
                        { field: 'pushSubscriptionStatus', title: 'Subscription Status', width: '200' },
                        { field: 'title', title: 'Title' },
                        { field: 'push_message', title: 'Push Message' }
                    ]];
                }

                return columns;
            },
            pushNotifications() {
                return this.allNotifications ? this.allNotifications.filter(noti => noti.push_message) : [];
            },
            selectedNotificationType() {
                if (this.selectedSubscriptionType === 'Email Notifications') {
                    return 'email';
                }

                if (this.selectedSubscriptionType === 'SMS Notifications') {
                    return 'SMS';
                }

                if (this.selectedSubscriptionType === 'Push Notifications') {
                    return 'Push';
                }
            },
            smsNotifications() {
                return this.allNotifications ? this.allNotifications.filter(noti => noti.sms_message) : [];
            },
            subscribeAllBtnDisabled() {
                return (this.selectedNotificationType === 'email' && this.emailSubscriptions?.length === 0)
                    || (this.selectedNotificationType === 'SMS' && this.subscribedToAllSMS)
                    || (this.selectedNotificationType === 'Push' && this.subscribedToAllPush);
            },
            subscribedToAllPush() {
                return this.pushSubscriptions?.some(setting => !setting.templateId && !setting.optOut);
            },
            subscribedToAllPushItem() {
                return this.pushSubscriptions?.find(setting => !setting.templateId && !setting.optOut);
            },
            subscribedToAllSMS() {
                return this.smsSubscriptions?.some(setting => !setting.templateId && !setting.optOut);
            },
            subscribedToAllSMSItem() {
                return this.smsSubscriptions?.find(setting => !setting.templateId && !setting.optOut);
            },
            subscribeSelectedBtnDisabled() {
                return (this.selectedNotiRows?.length === 0 
                    || (this.selectedNotificationType === 'email' && this.selectedNotiRows?.some(noti => noti.subscriptionStatus === 'Subscribed'))
                    || (this.selectedNotificationType === 'SMS' && this.selectedNotiRows?.some(noti => noti.smsSubscriptionStatus === 'Subscribed'))
                    || (this.selectedNotificationType === 'Push' && this.selectedNotiRows?.some(noti => noti.pushSubscriptionStatus === 'Subscribed' )));
            },
            unfilteredNotifications() {
                if (this.selectedSubscriptionType === 'Email Notifications') {
                    return cloneDeep(this.emailNotifications);
                }

                if (this.selectedSubscriptionType === 'SMS Notifications') {
                    return cloneDeep(this.smsNotifications);
                }

                if (this.selectedSubscriptionType === 'Push Notifications') {
                    return cloneDeep(this.pushNotifications);
                }
            },
            unsubscribeAllBtnDisabled() {
                return (this.selectedNotificationType === 'email' && this.unsubscribedFromAllEmailNotis)
                    || (this.selectedNotificationType === 'SMS' && this.smsSubscriptions?.length === 0)
                    || (this.selectedNotificationType === 'Push' && this.pushSubscriptions?.length === 0);
            },
            unsubscribedFromAllEmailNotis() {
                return this.emailSubscriptions?.some(setting => !setting.templateId && !setting.loanId);
            },
            unsubscribedFromAllEmailItem() {
                return this.emailSubscriptions?.find(setting => !setting.templateId && !setting.loanId);
            },
            unsubscribeSelectedBtnDisabled() {
                return (this.selectedNotiRows?.length === 0 
                    || (this.selectedNotificationType === 'email' && this.selectedNotiRows?.some(noti => noti.subscriptionStatus === 'Unsubscribed'))
                    || (this.selectedNotificationType === 'SMS' && this.selectedNotiRows?.some(noti => noti.smsSubscriptionStatus === 'Unsubscribed'))
                    || (this.selectedNotificationType === 'Push' && this.selectedNotiRows?.some(noti => noti.pushSubscriptionStatus === 'Unsubscribed')));
            }
        },
        watch: {
            selectedSubscriptionType(val) {
                if (val) {
                    this.filter = null;
                }
            },
            unfilteredNotifications(val) {
                if (val) {
                    this.getData();
                }
            }
        },
        async mounted() {
            const msalAppInstance = await auth.init();

            msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
                if (!tokenRes) {
                    if (!auth.user()) {
                        auth.login();
                    }
                }

                this.azureId = localStorage.getItem('azure_id');

                if (!this.azureId) return;

                await this.getUsersNotificationSubscriptionSettings();
                await this.getUsersSMSPushSubscriptionSettings();
                await this.getNotificationTemplates();

                this.loading = false;
            });
        },
        methods: {
            assignNotificationTemplates(templatesData) {
                templatesData.forEach(template => {
                    let subscriptionStatus = this.unsubscribedFromAllEmailNotis ? 'Unsubscribed' : '';
                    let smsSubscriptionStatus = this.subscribedToAllSMS ? 'Subscribed' : 'Unsubscribed';
                    let pushSubscriptionStatus = this.subscribedToAllPush ? 'Subscribed' : 'Unsubscribed';

                    if (template.html) {
                        if (!subscriptionStatus) {
                            let unsubscribedFullyFromTemplate = this.emailSubscriptions.some(setting => setting.templateId?.toLowerCase() === template.id?.toLowerCase() && !setting.loanId);

                            if (unsubscribedFullyFromTemplate) {
                                subscriptionStatus = 'Unsubscribed';
                            } else {
                                let unsubscribedPartiallyFromTemplate = this.emailSubscriptions.some(setting => setting.templateId?.toLowerCase() === template.id?.toLowerCase() && setting.loanId);

                                if (unsubscribedPartiallyFromTemplate) {
                                    subscriptionStatus = 'Partially Unsubscribed';
                                } else {
                                    subscriptionStatus = 'Subscribed';
                                }
                            }
                        } 

                        template.subscriptionStatus = subscriptionStatus;
                    }

                    if (template.sms_message) {
                        let subscriptionStatus = this.subscribedToAllSMS ? 'Subscribed' : '';

                        if (this.smsSubscriptions.some(setting => setting.templateId?.toLowerCase() === template.id?.toLowerCase())) {
                            smsSubscriptionStatus = 'Subscribed';
                        }

                        if (this.smsSubscriptions.some(setting => setting.templateId?.toLowerCase() === template.id?.toLowerCase() && setting.loanId)) {
                            smsSubscriptionStatus = 'Partially Unsubscribed';
                        }

                        template.smsSubscriptionStatus = smsSubscriptionStatus;
                    }

                    if (template.push_message) {
                        if (this.pushSubscriptions.some(setting => setting.templateId?.toLowerCase() === template.id?.toLowerCase())) {
                            pushSubscriptionStatus = 'Subscribed';
                        }

                        if (this.pushSubscriptions.some(setting => setting.templateId?.toLowerCase() === template.id?.toLowerCase() && setting.loanId)) {
                            pushSubscriptionStatus = 'Partially Unsubscribed';
                        }

                        template.pushSubscriptionStatus = pushSubscriptionStatus
                    }

                    template.displayName = `${template.title} - ${template.category}`;
                    template.selected = false;
                });

                templatesData.sort((a, b) => a.displayName.localeCompare(b.displayName));

                //only internal notifications for now...
                this.allNotifications = templatesData.filter(noti => noti.category === 'internal');

                this.getData();
            },
            createAppState(dataState) {
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            async deleteNotificationOptOuts(subIds) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.deleteNotificationOptOuts(subIds, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            async deletePushOptIns(subIds) {
                const tokenResponse = await auth.acquireTokenResponse();

               try {
                    await subscriptionHelpers.deletePushOptIns(subIds, tokenResponse.accessToken);
               } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
               }
            },
            async deleteSMSOptIns(subIds) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.deleteSMSOptIns(subIds, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            getAllNotificationsByType() {
                if (this.selectedSubscriptionType === 'Email Notifications') {
                    return this.emailNotifications;
                }

                if (this.selectedSubscriptionType === 'SMS Notifications') {
                    return this.smsNotifications;
                }

                if (this.selectedSubscriptionType === 'Push Notifications') {
                    return this.pushNotifications;
                }
            },
            getCurrentSubscriptionStatus() {
                if (this.selectedSubscriptionType === 'Email Notifications') {
                    return this.selectedNotification?.subscriptionStatus;
                }

                if (this.selectedSubscriptionType === 'SMS Notifications') {
                    return this.selectedNotification?.smsSubscriptionStatus;
                }

                if (this.selectedSubscriptionType === 'Push Notifications') {
                    return this.selectedNotification?.pushSubscriptionStatus;
                }
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;
            },
            getEmailSubscriptionCreates(subscriptionStatus) {
                let creates = [];

                if (subscriptionStatus === 'Subscribed') {
                    if (this.unsubscribedFromAllEmailNotis) {
                        let subscribedNotiIds = this.selectedNotiRows.map(row => row.id);

                        let optOuts = this.emailNotifications.filter(noti => !subscribedNotiIds.some(id => id === noti.id)).map(noti => {
                            return {
                                guid: null,
                                azureId: this.azureId,
                                templateId: noti.id,
                                loanId: null,
                                type: 'email'
                            }
                        });

                        creates = optOuts;
                    }
                }

                if (subscriptionStatus === 'Unsubscribed') {
                    creates = this.selectedNotiRows.map(noti => {
                        return {
                            guid: null,
                            azureId: this.azureId,
                            templateId: noti.id,
                            loanId: null,
                            type: 'email'
                        }
                    });
                }

                return creates;
            },
            getEmailSubscriptionDeletes(subscriptionStatus) {
                let deletes = [];

                if (subscriptionStatus === 'Subscribed') {
                    if (this.unsubscribedFromAllEmailItem) {
                        deletes.push(this.unsubscribedFromAllEmailItem.guid);
                    }

                    this.selectedNotiRows.forEach(row => {
                        let notificationSubscriptions = this.emailSubscriptions.filter(s => s.templateId?.toLowerCase() === row.id?.toLowerCase());

                        let subIds = notificationSubscriptions.map(sub => sub.guid);

                        deletes = [...deletes, ...subIds];
                    });
                }

                if (subscriptionStatus === 'Unsubscribed') {
                    this.selectedNotiRows.forEach(row => {
                        let notificationSubscriptions = this.emailSubscriptions.filter(s => s.templateId?.toLowerCase() === row.id?.toLowerCase());

                        let subIds = notificationSubscriptions.map(sub => sub.guid);

                        deletes = [...deletes, ...subIds];
                    });
                }

                return deletes;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.unfilteredNotifications.filter(item => {
                        let itemCopy = cloneDeep(item);

                        delete itemCopy.html;

                        if (this.selectedNotificationType === 'email') {
                            delete itemCopy.smsSubscriptionStatus;
                            delete itemCopy.pushSubscriptionStatus;
                        }

                        if (this.selectedNotificationType === 'SMS') {
                            delete itemCopy.subscriptionStatus;
                            delete itemCopy.pushSubscriptionStatus;
                        }

                        if (this.selectedNotificationType === 'Push') {
                            delete itemCopy.subscriptionStatus;
                            delete itemCopy.smsSubscriptionStatus;
                        }

                        return Object.values(itemCopy).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredItems = cloneDeep(this.unfilteredNotifications);
                }

                if (this.sort.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);
                } 

                this.getSelectedNotiRows();
                return this.filteredItems;
            },
            getNotificationSubscriptions() {
                if (this.selectedNotificationType === 'email') {
                    return this.emailSubscriptions.filter(s => s.templateId?.toLowerCase() === this.selectedNotification?.id?.toLowerCase());
                }

                if (this.selectedNotificationType === 'SMS') {
                    return this.smsSubscriptions.filter(s => s.templateId?.toLowerCase() === this.selectedNotification?.id?.toLowerCase());
                }

                if (this.selectedNotificationType === 'Push') {
                    return this.pushSubscriptions.filter(s => s.templateId?.toLowerCase() === this.selectedNotification?.id?.toLowerCase());
                }
            },
            async getNotificationTemplates() {
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetNotificationTemplates(tokenResponse.accessToken)
                    .then(res => {
                        if (res.data?.data) {
                            this.assignNotificationTemplates(res.data.data);
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Notification Templates';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            getSelectedNotiRows() {
                this.selectedNotiRows = this.unfilteredNotifications.filter(noti => noti.selected);
            },
            getSMSPushSubscriptionCreates(subscriptionStatus) {
                let creates = [];
                let subscribedToAll = this.selectedNotificationType === 'SMS' ? this.subscribedToAllSMS : this.subscribedToAllPush;
                let subscribedToAllItem = this.selectedNotificationType === 'SMS' ? this.subscribedToAllSMSItem : this.subscribedToAllPushItem;
                let allSubscriptions = this.selectedNotificationType === 'SMS' ? this.smsSubscriptions : this.pushSubscriptions;
                let allNotifications = this.getAllNotificationsByType();

                if (subscriptionStatus === 'Subscribed') {
                    this.selectedNotiRows.forEach(row => {
                        let notificationSubscriptions = allSubscriptions.filter(s => s.templateId?.toLowerCase() === row.id?.toLowerCase());
                        let subAll = notificationSubscriptions.find(s => !s.optOut);

                        if (!subAll) {
                            let subAll = {
                                guid: null,
                                azureId: this.azureId,
                                loanId: null,
                                templateId: row.id,
                                type: this.selectedNotificationType,
                                optOut: false
                            };

                            creates = [...creates, subAll];
                        }
                    });
                }

                if (subscriptionStatus === 'Unsubscribed') {
                    if (subscribedToAll) {
                        let unsubscribedNotiIds = this.selectedNotiRows.map(row => row.id);

                        let optIns = allNotifications.filter(noti => !unsubscribedNotiIds.some(id => id === notiId)).map(noti => {
                            return {
                                guid: null,
                                azureId: this.azureId,
                                templateId: noti.id,
                                loanId: null,
                                type: this.selectedNotificationType
                            }
                        });

                        creates = optIns;
                    }
                }

                return creates;
            },
            getSMSPushSubscriptionDeletes(subscriptionStatus) {
                let deletes = [];
                let subscribedToAll = this.selectedNotificationType === 'SMS' ? this.subscribedToAllSMS : this.subscribedToAllPush;
                let subscribedToAllItem = this.selectedNotificationType === 'SMS' ? this.subscribedToAllSMSItem : this.subscribedToAllPushItem;
                let allSubscriptions = this.selectedNotificationType === 'SMS' ? this.smsSubscriptions : this.pushSubscriptions;

                if (subscriptionStatus === 'Subscribed') {
                    this.selectedNotiRows.forEach(row => {
                        let notificationSubscriptions = allSubscriptions.filter(s => s.templateId?.toLowerCase() === row.id?.toLowerCase());
                        let subIds = notificationSubscriptions.filter(s => s.optOut).map(s => s.guid);

                        deletes = [...deletes, ...subIds];
                    });
                }

                if (subscriptionStatus === 'Unsubscribed') {
                    if (subscribedToAllItem) {
                        deletes.push(subscribedToAllItem.guid);
                    }

                    this.selectedNotiRows.forEach(row => {
                        let notificationSubscriptions = allSubscriptions.filter(s => s.templateId?.toLowerCase() === row.id?.toLowerCase());
                        let subIds = notificationSubscriptions.map(s => s.guid);

                        deletes = [...deletes, ...subIds];
                    });
                }

                return deletes;
            },
            async getUsersNotificationSubscriptionSettings() {
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetUsersNotificationSubscriptionSettings(this.azureId, tokenResponse.accessToken)
                    .then(res => {
                        if (res.data?.data) {
                            this.emailSubscriptions = res.data.data;
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Notification Templates';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            async getUsersSMSPushSubscriptionSettings() {
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetUsersSMSPushSubscriptionSettings(this.azureId, tokenResponse.accessToken)
                    .then(res => {
                        if (res.data?.data) {
                            this.pushSubscriptions = res.data?.data?.filter(sub => sub.type === 'Push');
                            this.smsSubscriptions = res.data?.data?.filter(sub => sub.type === 'SMS');
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Notification Templates';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            onHeaderSelectionChange(evt) {
                if (!evt?.event?.target) return;

                let checked = evt.event.target.checked;

                let filteredData = this.getFilteredItems();

                filteredData.forEach(noti => {
                    let allNotisMatch = this.unfilteredNotifications.find(n => n.id === noti.id);
                    
                    if (allNotisMatch) {
                        allNotisMatch[this.selectedField] = checked;
                    }
                });

                this.getSelectedNotiRows();
                
                this.getData();
            },
            onNotiSelectionChange(evt) {
                let noti = this.unfilteredNotifications.find(noti => noti.id === evt.dataItem?.id);

                if (noti) {
                    noti[this.selectedField] = !noti[this.selectedField];
                    this.getSelectedNotiRows();
                    this.getData();
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.loading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onRowClick(evt) {
                this.selectedNotification = evt.dataItem;
                this.selectedNotificationId = evt.dataItem.id;

                this.toggleEditSubscriptionDialog();
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            onSubscribeAllNotis() {
                this.confirmMsg = `Are you sure you would like to subscribe to all email notifications?`;
                this.confirmFunction = this.subscribeAllNotis;
                this.toggleConfirmDialog();
            },
            async onSubscribeSelectedNotis() {
                this.loading = true;
                let deletedSubscriptions = [];
                let createdSubscriptions = [];

                if (this.selectedNotificationType === 'email') {
                    deletedSubscriptions = this.getEmailSubscriptionDeletes('Subscribed');
                    createdSubscriptions = this.getEmailSubscriptionCreates('Subscribed');

                    await this.deleteNotificationOptOuts(deletedSubscriptions);
                    await this.upsertNotificationOptOuts(createdSubscriptions);
                }

                if (this.selectedNotificationType === 'SMS' || this.selectedNotificationType === 'Push') {
                    deletedSubscriptions = this.getSMSPushSubscriptionDeletes('Subscribed');
                    createdSubscriptions = this.getSMSPushSubscriptionCreates('Subscribed');

                    if (this.selectedNotificationType === 'SMS') {
                        if (deletedSubscriptions.length > 0) {
                            await this.deleteSMSOptIns(deletedSubscriptions);
                        }
                        if (createdSubscriptions.length > 0) {
                            await this.upsertSMSOptIns(createdSubscriptions);
                        }
                    } else {
                        if (deletedSubscriptions.length > 0) {
                            await this.deletePushOptIns(deletedSubscriptions);
                        }
                        if (createdSubscriptions.length > 0) {
                            await this.upsertPushOptIns(createdSubscriptions);
                        }
                    }
                }

                await this.onSubscriptionsUpdated();
                this.getSelectedNotiRows();

                this.loading = false;
                this.showNotification('Subscriptions updated successfully');
            },
            async onSubscriptionsUpdated() {
                await this.getUsersNotificationSubscriptionSettings();
                await this.getUsersSMSPushSubscriptionSettings();
                await this.getNotificationTemplates();

                if (this.showEditSubscriptionDialog) {
                    this.toggleEditSubscriptionDialog();
                }
            },
            onUnsubscribeAllNotis() {
                this.confirmMsg = `Are you sure you would like to unsubscribe from all email notifications?`;
                this.confirmFunction = this.unsubscribeAllNotis;
                this.toggleConfirmDialog();
            },
            async onUnsubscribeSelectedNotis() {
                this.loading = true;
                let deletedSubscriptions = [];
                let createdSubscriptions = [];

                if (this.selectedNotificationType === 'email') {
                    deletedSubscriptions = this.getEmailSubscriptionDeletes('Unsubscribed');
                    createdSubscriptions = this.getEmailSubscriptionCreates('Unsubscribed');

                    await this.deleteNotificationOptOuts(deletedSubscriptions);
                    await this.upsertNotificationOptOuts(createdSubscriptions);
                }

                if (this.selectedNotificationType === 'SMS' || this.selectedNotificationType === 'Push') {
                    deletedSubscriptions = this.getSMSPushSubscriptionDeletes('Unsubscribed');
                    createdSubscriptions = this.getSMSPushSubscriptionCreates('Unsubscribed');

                    if (this.selectedNotificationType === 'SMS') {
                        if (deletedSubscriptions.length > 0) {
                            await this.deleteSMSOptIns(deletedSubscriptions);
                        }
                        if (createdSubscriptions.length > 0) {
                            await this.upsertSMSOptIns(createdSubscriptions);
                        }
                    } else {
                        if (deletedSubscriptions.length > 0) {
                            await this.deletePushOptIns(deletedSubscriptions);
                        }
                        if (createdSubscriptions.length > 0) {
                            await this.upsertPushOptIns(createdSubscriptions);
                        }
                    }
                }

                await this.onSubscriptionsUpdated();
                this.getSelectedNotiRows();

                this.loading = false;
                this.showNotification('Subscriptions updated successfully');
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            async subscribeAllNotis() {
                this.toggleConfirmDialog();
                this.loading = true;

                if (this.selectedNotificationType === 'email') {
                    let deleteIds = this.emailSubscriptions?.map(sub => sub.guid);

                    if (deleteIds?.length > 0) {
                        await this.deleteNotificationOptOuts(deleteIds);
                    }
                }

                await this.onSubscriptionsUpdated();
                this.getSelectedNotiRows();

                this.loading = false;
                this.showNotification('Subscriptions updated successfully');

            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;

                if (!this.showConfirmDialog) {
                    this.confirmMsg = '';
                    this.confirmFunction = null;
                }
            },
            toggleEditSubscriptionDialog() {
                this.showEditSubscriptionDialog = !this.showEditSubscriptionDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                }
            },
            async unsubscribeAllNotis() {
                this.toggleConfirmDialog();
                this.loading = true;

                if (this.selectedNotificationType === 'email') {
                    let deleteIds = this.emailSubscriptions?.map(sub => sub.guid);

                    if (deleteIds?.length > 0) {
                        await this.deleteNotificationOptOuts(deleteIds);
                    }

                    let optOut = {
                        guid: null,
                        azureId: this.azureId,
                        loanId: null,
                        templateId: null,
                        type: 'email'
                    };

                    await this.upsertNotificationOptOuts([optOut]);
                }

                await this.onSubscriptionsUpdated();
                this.getSelectedNotiRows();

                this.loading = false;
                this.showNotification('Subscriptions updated successfully');
            },
            async upsertNotificationOptOuts(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.upsertNotificationOptOuts(request, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            async upsertPushOptIns(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.upsertPushOptIns(request, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            async upsertSMSOptIns(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.upsertSMSOptIns(request, tokenResponse.accessToken)
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            }
        },
        components: {
            'k-dropdown': DropDownList,
            'k-loader': Loader,
            'k-dialog': Dialog,
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-input': Input,
            'k-button': Button,
            'edit-subscription-dialog': editSubscriptionDialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'confirm-dialog': confirmDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/subscriptionManagement.css';
</style>
