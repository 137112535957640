<template>
    <div class="notifications-pipeline">
        <div class="pipeline-container">
            <k-grid :data-items="filteredItems"
                    :data-item-key="'id'"
                    :columns="pipelineColumns"
                    :column-menu="true"
                    :sortable="true"
                    :pageable="gridPageable"
                    :filterable="true"
                    :sort="sort"
                    :filter="filter"
                    :take="take"
                    :total="total"
                    :skip="skip"
                    :selected-field="selectedField"
                    @datastatechange="dataStateChange"
                    @rowclick="onRowClick"
                    @filterchange="onFilterChange"
                    @sortchange="onSortChange"> 
                    <k-grid-toolbar>
                            <h2 class="pipeline-title"><i class="fa-light fa-envelopes"></i> Notifications <span>Select a notification to preview</span></h2>

                            <span class="k-textbox k-grid-search k-display-flex">
                                <k-input :style="{ width: '300px' }"
                                        placeholder="Search in all columns..."
                                        :value="searchWord"
                                        :icon-name="'search'"
                                        @input="onSearchChange"></k-input>
                            </span>
                    </k-grid-toolbar>
            </k-grid>
        </div>

        <notification-preview-dialog :show-dialog="showNotificationPreviewDialog"
                                     :notification="selectedNotification"
                                     @notificationadded="onNotificationAdded"
                                     @dialogclosed="toggleNotificationPreviewDialog"></notification-preview-dialog>
    </div>
</template>

<script>
    import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
    import { Input } from '@progress/kendo-vue-inputs';

    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import cloneDeep from 'lodash.clonedeep';

    import notificationPreviewDialog from '../components/notificationPreviewDialog.vue';

    export default {
        name: 'NotificationsPipeline',
        props: ['notifications'],
        emits: ['notificationadded'],
        data() {
            return {
                allNotifications: [],
                filter: null,
                filteredItems: [],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                pipelineColumns: [
                    { field: 'category', title: 'Category', width: '150' },
                    { field: 'title', title: 'Title' },
                    { field: 'subject', title: 'Notification Subject' }
                ],
                searchWord: '',
                selectedField: 'selected',
                selectedId: null,
                selectedNotification: null,
                showNotificationPreviewDialog: false,
                skip: 0,
                sort: [],
                take: 50,
                total: 0

            }
        },
        mounted() {
            this.allNotifications = cloneDeep(this.notifications);
            this.getData();
        },
        methods: {
            createAppState(dataState) {
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.allNotifications.filter(item => {
                        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredItems = cloneDeep(this.allNotifications);
                }

                if (this.sort.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);
                } 

                return this.filteredItems;
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            onNotificationAdded() {
                this.$emit('notificationadded', this.selectedNotification);
            },
            onRowClick(evt) {
                this.selectedNotification = evt.dataItem;
                this.selectedId = evt.dataItem.id;

                this.allNotifications.forEach(item => {
                    if (item.id === this.selectedId) {
                        item.selected = true;
                    } else {
                        item.selected = false;
                    }
                });

                this.getData();

                this.toggleNotificationPreviewDialog();
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            toggleNotificationPreviewDialog() {
                this.showNotificationPreviewDialog = !this.showNotificationPreviewDialog;
            }
        },
        components: {
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-input': Input,
            'notification-preview-dialog': notificationPreviewDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/notificationsPipeline.css';
</style>