<template>
  <div class="dashboard-container">
    <div class="card-wrapper">
      <div class="card-content-wrapper" :style="{ borderColor: borderColor }">
        <div class="card-header">
          <div class="header-icon">
            <i class="fa-duotone fa-solid fa-bell"></i>
          </div>
          <div class="header-text">
            {{ title }}
          </div>
        </div>
        <div class="card-content">
          <Grid v-if="cardType=='grid'"
                :style="{ height: '100%', width: '100%' }"
                :data-items="result"
                :columns="columns"
                :total="total"
                :sortable="true"
                :sort="sort"
                @sortchange="sortChangeHandler"
                :skip="10"
                :page-size="10"
                :loader="!columnsLoaded"
                @rowclick="handleRowClick">
            <template v-slot:subjectCell="{ props }">
              <td class="subject-cell">{{ props.dataItem.subject }}</td>
            </template>
          </Grid>
          <div v-if="cardType=='total'">
            <loader v-if="!columnsLoaded"></loader>
            <div v-if="columnsLoaded" class="totals-content">
              <strong>{{ content }}</strong>
            </div>
          </div>
          <div v-if="cardType==='tabbedGrids'" class="tab-container">
            <TabStrip v-if="cardType==='tabbedGrids'" :selected="selected" @select="onSelect" :tabs="tabs">
              <template v-slot:grid>
                <Grid

                      :style="{ height: '100%', width: '100%'}"
                      :data-items="result[selected]"
                      :columns="columns[selected]"
                      :total="total"
                      :sortable="true"
                      :sort="sort"
                      @sortchange="sortChangeHandler"
                      :skip="skip"
                      :page-size="10"
                      :loader="!columnsLoaded"
                      @rowclick="handleRowClick">
                  <template v-slot:subjectCell="{ props }">
                    <td class="subject-cell">{{ getNestedValue(props.field, props.dataItem) }}</td>
                  </template>
                </Grid>
              </template>
            </TabStrip>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { orderBy } from '@progress/kendo-data-query';
  import { Grid } from '@progress/kendo-vue-grid';
  import { Loader } from '@progress/kendo-vue-indicators';
  import { TabStrip } from '@progress/kendo-vue-layout';

  export default {
    components: {
      Grid,
      loader: Loader,
      TabStrip,
    },
    computed: {
      result: {
        get: function () {
          if (Array.isArray(this.gridData[0])) {
            let sortedData = [];
            for (let i = 0; i < this.gridData.length; i++) {
              sortedData[i] = orderBy(this.gridData[i], this.sort);
            }
            return sortedData;
          } else {
            return orderBy(this.gridData, this.sort);
          }
        },
      },
      total() {
        return this.gridData.length;
      },
    },
    data() {
      return {
        filter: null,
        group: [],
        searchFilter: null,
        selected: 0,
        skip: 10,
        sort: [],
        subjectCell: 'subjectCell',
        take: 10,
        updatedData: [],
      };
    },
    emits: ['row-clicked'],
    props: {
      borderColor: String,
      cardHeight: String,
      cardType: String,
      columns: {
        type: Array,
      },
      columnsLoaded: Boolean,
      content: String,
      gridData: {
        type: Array,
      },
      tabs: Array,
      title: String,
    },
    methods: {
      getNestedValue: function(fieldName, dataItem) {
            const path = fieldName.split('.');
            let data = dataItem;
            path.forEach((p) => {
                data = data ? data[p] : undefined;
            });

            return data;
        },
      handleRowClick(event) {
        this.$emit('row-clicked', event.dataItem);
      },
      onSelect(e) {
        this.selected = e.selected;
      },
      sortChangeHandler(e) {
        this.sort = e.sort;
      },
    },
  };
</script>

<style>
  root {
      font-size: 14px;
  }
  .card-content {
    justify-content: center;
    height: 100%;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    scrollbar-width: none;
    width: 100%;
  }
    /* Hide scrollbar for WebKit browsers */
  .card-content::-webkit-scrollbar {
    display: none;
  }
  .card-content-wrapper {
    height: 100%;
    border-bottom: 4px solid #398b87;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 10px;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    /*padding: 10px 0;*/
    color: rgb(128, 128, 128, 0.6);
    border-bottom: 1px solid rgba(128, 128, 128, 0.6);
  }
  .card-title {
    border-bottom: 1px solid #e0e0e0;
    color: rgb(128, 128, 128, 0.6);
    display: flex;
    font-size: 1.5rem;
    margin: 0 5px;
    padding: 5px 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  .card-wrapper {
    height: 100%;
    align-items: center;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    inset: 10px;
    justify-content: center;
    width: 100%;

  }
  .clickable {
    cursor: pointer;
  }
  .dashboard-container {
    height: 100%;
    padding: 3px;
  }

  .grid-card-loader-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  .header-icon {
    font-size: 4rem;
  }

  .header-icon i {
    --fa-primary-color: #051d3a;
  }
  .header-text {
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    padding-left: 10px;
  }
  .subject-cell {
    height: 70px;
    cursor: pointer;
    font-size: 1.3rem;
    white-space: normal;
    overflow-wrap: break-word;
  }
  .tab-container{
      height: calc(100% - 90px);
      overflow: hidden;
      padding: 10px;
  }
  .totals-content {
    padding: 5px;
    font-size: 3rem;
  }
  /*Annoying kendo css selectors*/
  #app > div:nth-child(2) > div > div.dashboard-container > div > div > div.card-content > div > div.k-content.k-active{
      height: 100%;
  }
  #app > div:nth-child(2) > div > div.dashboard-container > div > div > div.card-content > div > div.k-content.k-active > div.k-animation-container.k-animation-container-relative.k-fade-enter-active > div{
      height: 100%;
  }
  [role=tabpanel]{
      height: 100%;
  }
  #app > div:nth-child(2) > div > div.dashboard-container > div > div > div.card-content > div{
      height: 100%;
  }
  #app > div:nth-child(2) > div > div.dashboard-container > div > div > div.card-content > div > div.k-content.k-active > div.k-animation-container.k-animation-container-relative.k-fade-enter-active{
      height: 100%;
  }
  #app > div:nth-child(2) > div > div.dashboard-container > div > div > div.card-content > div > div.k-content.k-active{
      overflow: hidden;
  }
  .k-tabstrip{
      height: 100%
  }
  .k-tabstrip > .k-content.k-active{
      height: 100px;
      overflow: hidden;
  }
  .tab-container .k-tabstrip .k-content .k-animation-container{
      height: 100%;
      overflow: hidden;
  }
  .k-child-animation-container{
      height: 100%;
  }

  @media (max-width: 800px) {
  }
  @media (max-width: 480px) {
    .card-title{
        font-size: .7rem;
        margin: auto;
    }
    .header-icon {
      font-size: 3rem;
    }

    .header-text {
      font-size: 1.5rem;
    }
    .k-tabstrip-items .k-link {
      font-size: 1rem;
    }
    .subject-cell {
    font-size: 0.8rem;
    }
    .totals-content {
      font-size: 2rem;
    }

  }
  @media (max-width: 375px){
      .totals-content{
          font-size: 2rem;
      }

  }
</style>
