<template>
    <transition name="dialogfade">
        <k-dialog v-if="showDialog" class="default__dialog confirm-dialog" @close="onCloseDialog" :title="dialogTitle">
            <div class="confirm-msg__container">
                <p class="confirm-dialog__msg">{{ dialogMsg }}</p>
            </div>
            <k-action-bar>
                <k-button class="item-confirm__btn" @click="onConfirmClicked">Confirm</k-button>
                <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
            </k-action-bar>
        </k-dialog>
    </transition>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        props: ['dialogMsg', 'showDialog'],
        emits: ['cancelclicked', 'confirmclicked', 'dialogclosed'],
        name: 'confirmDialog',
        data() {
            return {
                dialogTitle: 'Please Confirm'
            }
        },
        methods: {
            onCancelClicked() {
                this.$emit('cancelclicked');
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onConfirmClicked() {
                this.$emit('confirmclicked');
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button
        }
    }
</script>

<style>
    @import '/src/assets/css/confirmDialog.css';
</style>