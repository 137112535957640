<template>
  <div class="summary-card-component-cont" v-for="(summary, index) in summaryCardTitles" :key="index">
    <div v-if="summary.value !== undefined && !Array.isArray(summary.value)" class="summary-card-component-wrapper">
      <div class="card-component-content-wrapper">
        <div class="card-component-content-cont">
          <div class="card-component-header">
            <div>{{ summary.title }}</div>
          </div>
          <div class="card-component-content">
            <div class="totals-component-content">
              <strong>{{ summary.value }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="summary.value !== undefined && Array.isArray(summary.value)" class="summary-card-component-wrapper">
      <div class="card-component-content-wrapper">
        <div class="card-component-content-cont" @click="emitOnOpenSummaryDialog(summary.title)" style="cursor: pointer">
          <div class="card-component-header">
            <div>{{ summary.title }}</div>
          </div>
          <div class="card-component-content">
            <div class="totals-component-content">
              <strong>click for data</strong>
            </div>
          </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    emits: ['open-summary-dialog'],
    props: {
      postCloseSummaryData: {
        type: Array,
        required: false,
        default: () => []
      },
      summaryCardTitles: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    data() {
      return {

      };
    },
    methods: {
      emitOnOpenSummaryDialog(title) {
        this.$emit('open-summary-dialog', title);
      }
    }
  };
</script>

<style scoped>
  @import '/src/assets/css/summaryCard.css';
</style>
