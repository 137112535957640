<template>
    <div class="edit-subscription">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog edit-subscription-dialog" 
                      @close="onCloseDialog" 
                      :title="`Edit ${notificationType?.charAt(0).toUpperCase() + notificationType?.slice(1)} Subscription: ${notification?.title}`">
                <div class="body-container">
                    <div class="edit-container">
                        <div class="sub-info-container">
                            <p class="info-title"><i class="fa-solid fa-circle-info"></i> Subscription Types</p>
                            <p class="sub-info">Subscribed: <span>Subscribed to notification for all loans</span></p>
                            <p class="sub-info">Unsubscribed: <span>Unsubscribed to notification for all loans</span></p>
                            <p class="sub-info">Partially Unsubscribed: <span>Unsubscribed to notification for specific loans</span></p>
                        </div>
                        <div class="subscription-checkboxes">
                            <h2 class="subscription-title"><i class="fa-duotone fa-solid fa-envelope"></i> Edit Subscription Status</h2>
                            <k-checkbox v-model="subscribed" :label="'Subscribed'" @change="updateStatus('Subscribed')" />
                            <k-checkbox v-model="unsubscribed" :label="'Unsubscribed'" @change="updateStatus('Unsubscribed')" />
                            <k-checkbox v-model="partiallyUnsubscribed" :label="'Partially Unsubscribed'" @change="updateStatus('Partially Unsubscribed')" />
                        </div>

                        <k-fade :appear="editedSubscriptionStatus === 'Partially Unsubscribed'">
                            <div v-if="editedSubscriptionStatus === 'Partially Unsubscribed'" class="unsubscribed-loans-container">
                                <label class="loan-number-label">Enter Loan Number to Unsubscribe
                                    <span class="input-row">
                                        <k-input class="loan-number-input" v-model="unsubscribedLoanNumber" @input="resetErrors" />
                                        <k-button class="add-loan-btn" @click="onAddLoanNumber" :disabled="!unsubscribedLoanNumber">Add</k-button>
                                    </span>
                                    <span v-if="showDuplicateLoanError" class="duplicate-error"><i class="fa-regular fa-circle-exclamation"></i> Loan has already been added</span>
                                    <span v-if="showInvalidLoanError" class="duplicate-error"><i class="fa-regular fa-circle-exclamation"></i> Loan not found</span>
                                </label>

                                <div class="unsubscribed-list-container">
                                    <k-card :orientation="'vertical'" class="loans-card">
                                        <k-card-header>
                                            <p class="loans-card-title"><i class="fa-sharp-duotone fa-solid fa-layer-group"></i> Unsubscribed Loans</p>
                                        </k-card-header>
                                        <k-card-body>
                                            <k-listview :data-items="unsubscribedLoans" v-if="unsubscribedLoans?.length > 0"
                                                        :item="'loan'"
                                                        class="unsubscribed-list">    
                                                <template v-slot:loan="{ props }">
                                                    <div class="loan-row">
                                                        <p class="borrower-name">{{ props.dataItem.borrowerFirst }} {{ props.dataItem.borrowerLast }}</p>
                                                        <p class="loan-number">{{ props.dataItem.loanNumber }}</p>
                                                        <span title="Remove Loan" @click="onRemoveLoan(props.dataItem)" class="remove-loan"><i class="fa-regular fa-xmark"></i></span>
                                                    </div>
                                                </template>
                                            </k-listview>
                                            <p class="no-loans" v-if="unsubscribedLoans?.length === 0"><i class="fa-duotone fa-solid fa-folder-magnifying-glass"></i> No Loans Found</p>
                                        </k-card-body>
                                    </k-card>
                                </div>
                            </div>
                        </k-fade>
                    </div>
                    <div class="preview-container" v-if="notification" v-html="notification.html"></div>
                </div>
                <k-action-bar>
                    <k-button class="item-save__btn" @click="onSaveClicked" :disabled="saveChangesBtnDisabled">Save</k-button>
                    <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                </k-action-bar>

                <div class="component__loader-container" v-if="loading">
                    <k-loader size="large" type="infinite-spinner"></k-loader>
                </div>


            </k-dialog>
        </transition>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default__notification-container">
            <k-fade :appear="successNotification">
                <k-notification v-if="successNotification"
                                :type="{ style: 'success', icon: true }"
                                :closable="true"
                                @close="onCloseNotification('successNotification')">
                    <span>{{ notificationMsg }}</span>
                </k-notification>
            </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Checkbox } from "@progress/kendo-vue-inputs";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Input } from '@progress/kendo-vue-inputs';
    import { ListView } from '@progress/kendo-vue-listview';
    import {
        Card,
        CardHeader,
        CardBody,
    } from '@progress/kendo-vue-layout';

    import apiService from '../api/api.service.js';
    import auth from '../authConfig.js';

    import cloneDeep from 'lodash.clonedeep';
    import isEqual from 'lodash.isequal';

    import subscriptionHelpers from '../helpers/subscriptionHelpers.js';

    export default {
        props: ['allNotifications', 'azureId', 'currentSubscriptionStatus', 'notification', 'notificationSubscriptions', 
                'notificationType', 'showDialog', 'subscribedToAllPush', 'subscribedToAllPushItem', 'subscribedToAllSms',
                'subscribedToAllSmsItem', 'unsubscribedFromAllEmails', 'unsubscribedFromAllEmailsItem'],
        emits: ['dialogclosed', 'subscriptionsupdated'],
        name: 'editSubscriptionDialog',
        data() {
            return {
                editedSubscriptions: [],
                editedSubscriptionStatus: null,
                errorMsg: '',
                errorTitle: '',
                loading: false,
                notificationMsg: '',
                partiallyUnsubscribed: false,
                showDuplicateLoanError: false,
                showErrorDialog: false,
                showInvalidLoanError: false,
                subscribed: false,
                successNotification: false,
                uneditedUnsubscribedLoans: [],
                unsubscribed: false,
                unsubscribedLoanNumber: null,
                unsubscribedLoans: [],
                uneditedSubscriptions: [],
                uneditedSubscriptionStatus: null
            }
        },
        computed: {
            saveChangesBtnDisabled() {
                let uneditedLoans;
                let editedLoans;

                if (this.uneditedSubscriptionStatus === 'Partially Unsubscribed' && this.editedSubscriptionStatus === 'Partially Unsubscribed') {
                    let uneditedLoans = cloneDeep(this.uneditedUnsubscribedLoans);
                    let editedLoans = cloneDeep(this.unsubscribedLoans);

                    uneditedLoans.sort((a, b) => a.loanNumber.localeCompare(b.loanNumber));
                    editedLoans.sort((a, b) => a.loanNumber.localeCompare(b.loanNumber));

                    if (this.unsubscribedLoans?.length === 0 || isEqual(uneditedLoans, editedLoans)) {
                        return true;
                    } else {
                        return false;
                    }
                }

                return  (this.editedSubscriptionStatus === 'Partially Unsubscribed' && this.unsubscribedLoans?.length === 0)                       
                        || this.uneditedSubscriptionStatus === this.editedSubscriptionStatus;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.uneditedSubscriptions = cloneDeep(this.notificationSubscriptions);

                    this.unsubscribedLoans = this.notificationSubscriptions?.reduce((prev, cur) => {
                        if (cur.loanNumber) {
                            prev.push({
                                borrowerFirst: cur.borrowerFirst,
                                borrowerLast: cur.borrowerLast,
                                loanId: cur.loanId,
                                loanNumber: cur.loanNumber
                            });
                        }

                        return prev;
                    }, []);

                    this.uneditedUnsubscribedLoans = cloneDeep(this.unsubscribedLoans);

                    if (this.notification) {
                        this.uneditedSubscriptionStatus = this.currentSubscriptionStatus;
                        this.editedSubscriptionStatus = this.currentSubscriptionStatus;
                        this.setCurrentSubscriptionStatus(this.uneditedSubscriptionStatus);
                    }
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            async deleteNotificationOptOuts(subIds) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.deleteNotificationOptOuts(subIds, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            async deletePushOptIns(subIds) {
                const tokenResponse = await auth.acquireTokenResponse();

               try {
                    await subscriptionHelpers.deletePushOptIns(subIds, tokenResponse.accessToken);
               } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
               }
            },
            async deleteSMSOptIns(subIds) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.deleteSMSOptIns(subIds, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            async getLoanDisplayData(loanNumber) {
                this.loading = true;
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetLoanDisplayData(loanNumber, tokenResponse.accessToken) 
                    .then(res => {
                        this.loading = false;

                        if (res?.data?.data) {
                            this.unsubscribedLoans.push(res.data.data);
                            this.unsubscribedLoanNumber = null;
                        } else {
                            this.showInvalidLoanError = true;
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Find Loan by Loan Number';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            async onAddLoanNumber() {
                let loan = this.unsubscribedLoans.find(loan => loan.loanNumber === this.unsubscribedLoanNumber);

                if (!loan) {
                    this.showDuplicateLoanError = false;
                    await this.getLoanDisplayData(this.unsubscribedLoanNumber);
                } else {
                    this.showDuplicateLoanError = true;
                }
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onRemoveLoan(loan) {
                let removedIndex = this.unsubscribedLoans.findIndex(l => l.loanId === loan.loanId);

                if (removedIndex > -1) {
                    this.unsubscribedLoans.splice(removedIndex, 1);
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.loading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveClicked() {
                this.loading = true;

                if (this.notificationType === 'email') {
                    this.saveEmailSubscriptions();
                }

                if (this.notificationType === 'SMS' || this.notificationType === 'Push') {
                    this.saveSMSPushSubscriptions();
                }
            },
            resetDefaults() {
                this.editedSubscriptions = [];
                this.uneditedSubscriptions = [];
                this.uneditedSubscriptionStatus = null;
                this.editedSubscriptionStatus = null;
                this.unsubscribedLoans = [];
                this.uneditedUnsubscribedLoans = [];
                this.unsubscribedLoanNumber = null;
                this.showDuplicateLoanError = false;
                this.showInvalidLoanError = false;
            },
            resetErrors() {
                this.showDuplicateLoanError = false;
                this.showInvalidLoanError = false;
            },
            async saveEmailSubscriptions() {
                if (this.editedSubscriptionStatus === 'Subscribed') {
                    let subIds = this.notificationSubscriptions.map(sub => sub.guid);

                    if (this.unsubscribedFromAllEmailsItem) {
                        subIds.push(this.unsubscribedFromAllEmailsItem.guid);
                    }

                    if (subIds.length > 0) {
                        await this.deleteNotificationOptOuts(subIds);
                    }

                    if (this.unsubscribedFromAllEmails) {
                        let optOuts = this.allNotifications.filter(noti => noti.id !== this.notification.id).map(noti => {
                            return {
                                guid: null,
                                azureId: this.azureId,
                                templateId: noti.id,
                                loanId: null,
                                type: 'email'
                            }
                        });

                        await this.upsertNotificationOptOuts(optOuts);
                    }
                }

                if (this.editedSubscriptionStatus === 'Unsubscribed') {
                    let subIds = this.notificationSubscriptions.map(sub => sub.guid);

                    if (subIds.length > 0) {
                        await this.deleteNotificationOptOuts(subIds);
                    }

                    let optOuts = [
                        {
                            guid: null,
                            azureId: this.azureId,
                            templateId: this.notification.id,
                            loanId: null,
                            type: 'email'
                        }
                    ]

                    await this.upsertNotificationOptOuts(optOuts);
                }

                if (this.editedSubscriptionStatus === 'Partially Unsubscribed') {
                    let subIds = this.notificationSubscriptions.map(sub => sub.guid);

                    if (this.unsubscribedFromAllEmailsItem) {
                        subIds.push(this.unsubscribedFromAllEmailsItem.guid);
                    }

                    if (subIds.length > 0) {
                        await this.deleteNotificationOptOuts(subIds);
                    }

                    let optOuts = [];

                    if (this.unsubscribedFromAllEmails) {
                        optOuts = this.allNotifications.filter(noti => noti.id !== this.notification.id).map(noti => {
                            return {
                                guid: null,
                                azureId: this.azureId,
                                templateId: noti.id,
                                loanId: null,
                                type: 'email'
                            }
                        });
                    }

                    optOuts = [...optOuts, ...this.unsubscribedLoans.map(loan => {
                        return {
                            guid: null,
                            azureId: this.azureId,
                            templateId: this.notification.id,
                            loanId: loan.loanId,
                            type: 'email'
                        }
                    })];

                    await this.upsertNotificationOptOuts(optOuts);
                }

                this.$emit('subscriptionsupdated');

                this.showNotification('Subscriptions updated successfully');
                this.loading = false;
            },
            async saveSMSPushSubscriptions() {
                let subscribedToAll = this.notificationType === 'SMS' ? this.subscribedToAllSms : this.subscribedToAllPush;
                let subscribedToAllItem = this.notificationType === 'SMS' ? this.subscribedToAllSmsItem : this.subscribedToAllPushItem;

                if (this.editedSubscriptionStatus === 'Subscribed') {
                    let subAll = this.notificationSubscriptions.find(sub => !sub.optOut);

                    let subIds = this.notificationSubscriptions.filter(sub => sub.optOut).map(sub => sub.guid);

                    if (subIds.length) {
                        if (this.notificationType === 'SMS') {
                            await this.deleteSMSOptIns(subIds);
                        } else {
                            await this.deletePushOptIns(subIds);
                        }
                    }

                    if (!subAll) {
                        let request = [
                            {
                                guid: null,
                                azureId: this.azureId,
                                loanId: null,
                                templateId: this.notification.id,
                                type: this.notificationType,
                                optOut: false
                            }
                        ]

                        if (this.notificationType === 'SMS') {
                            await this.upsertSMSOptIns(request);
                        } else {
                            await this.upsertPushOptIns(request);
                        }

                    }
                }

                if (this.editedSubscriptionStatus === 'Unsubscribed') {
                    let subIds = this.notificationSubscriptions.map(sub => sub.guid);

                    if (subscribedToAllItem) {
                        subIds.push(subscribedToAllItem.guid);
                    }

                    if (subIds.length > 0) {
                        await this.deleteSMSOptIns(subIds);
                    }

                    if (subscribedToAll) {
                        let optIns = this.allNotifications.filter(noti => noti.id !== this.notification.id).map(noti => {
                            return {
                                guid: null,
                                azureId: this.azureId,
                                templateId: noti.id,
                                loanId: null,
                                type: this.notificationType,
                                optOut: false
                            }
                        });

                        if (this.notificationType === 'SMS') {
                            await this.upsertSMSOptIns(optIns);
                        } else {
                            await this.upsertPushOptIns(optIns);
                        }
                    }
                }

                if (this.editedSubscriptionStatus === 'Partially Unsubscribed') {
                    let subAll = this.notificationSubscriptions.find(sub => !sub.optOut);

                    let subIds = this.notificationSubscriptions.filter(sub => sub.optOut).map(sub => sub.guid);

                    if (subscribedToAllItem) {
                        subIds.push(subcribedToAllItem.guid);
                    }

                    if (subIds.length > 0) {
                        if (this.notificationType === 'SMS') {
                            await this.deleteSMSOptIns(subIds);
                        } else {
                            await this.deletePushOptIns(subIds);
                        }
                    }

                    let optIns = [];

                    if (subscribedToAll) {
                        optIns = this.allNotifications.filter(noti => noti.id !== this.notification.id).map(noti => {
                            return {
                                guid: null,
                                azureId: this.azureId,
                                templateId: noti.id,
                                loanId: null,
                                type: this.notificationType,
                                optOut: false
                            }
                        });
                    }

                    if (!subAll) {
                        optIns.push({
                            guid: null,
                            azureId: this.azureId,
                            loanId: null,
                            templateId: this.notification.id,
                            type: this.notificationType,
                            optOut: false
                        });
                    }

                    optIns = [...optIns, ...this.unsubscribedLoans.map(loan => {
                        return {
                            guid: null,
                            azureId: this.azureId,
                            templateId: this.notification.id,
                            loanId: loan.loanId,
                            type: this.notificationType,
                            optOut: true
                        }
                    })];

                    if (this.notificationType === 'SMS') {
                        await this.upsertSMSOptIns(optIns);
                    } else {
                        await this.upsertPushOptIns(optIns);
                    }
                }

                this.loading = false;
                this.$emit('subscriptionsupdated');

                this.showNotification('Subscriptions updated successfully');
            },
            setCurrentSubscriptionStatus(status) {
                this.subscribed = status === 'Subscribed';
                this.unsubscribed = status === 'Unsubscribed';
                this.partiallyUnsubscribed = status === 'Partially Unsubscribed';
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                }
            },
            updateStatus(status) {
                this.editedSubscriptionStatus = status;
                this.setCurrentSubscriptionStatus(this.editedSubscriptionStatus);
            },
            async upsertNotificationOptOuts(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.upsertNotificationOptOuts(request, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            async upsertPushOptIns(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.upsertPushOptIns(request, tokenResponse.accessToken);
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            },
            async upsertSMSOptIns(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                try {
                    await subscriptionHelpers.upsertSMSOptIns(request, tokenResponse.accessToken)
                } catch (error) {
                    let errorTitle = 'Failed to Update Subscriptions';

                    let errorMsg;

                    if(error.response?.data?.data) {
                        errorMsg = "Error: \n\n" + error.response.data.data;
                    } else {
                        errorMsg = '';
                    }

                    this.onRequestError(errorTitle, errorMsg);
                }
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-checkbox': Checkbox,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-input': Input,
            'k-listview': ListView,
            'k-card': Card,
            'k-card-header': CardHeader,
            'k-card-body': CardBody
        }
    }

</script>

<style>
    @import '/src/assets/css/editSubscriptionDialog.css';
</style>