<template>
  <div class="loans-pipeline__container">
    <postCloseConnect  v-if="userRoles.includes('Corp')"/>
    <div v-else class="no-permissions-view">You do not have permissions to view this page.</div>
  </div>
</template>

<script>
  import auth from '../authConfig.js';
  import postCloseConnect from '../components/postCloseConnect.vue';

  export default {
    name: 'App',
    components: {
      "postCloseConnect": postCloseConnect
    },
    data() {
      return {
        // MSAL
        idTokenClaims: null,
        userRoles: [],
      }
    },
    async created() {

      const msalAppInstance = await auth.init();

      msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {

        if (!tokenRes) {
          if (!auth.user()) {
            auth.login();
          }
        }
      });
    },
    mounted() {
      let userRolesFromLocalStorage = localStorage.getItem('user_roles');

      try {
        if (userRolesFromLocalStorage) {
          this.userRoles = userRolesFromLocalStorage.split(',');
        } else {
          this.userRoles = [];
        }
      } catch (error) {
        console.warn("Failed to get user_roles.");
      }
    },
    methods: {

    }

  }
</script>

<style scoped>
  .loans-pipeline__container {
    height: 100vh;
    overflow: auto;
  }

  .no-permissions-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-weight: 700;
    opacity: 0.7;
  }
</style>
