<template>
    <div class="edit-setting">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog edit-setting-dialog" @close="onCloseDialog" :title="dialogTitle">
                <div class="setting-container" v-if="editedSetting">
                    <div class="setting-item">
                        <label>Notifications To</label>
                        <k-input disabled v-model="editedSetting.email_address" />
                    </div>
                    <div class="setting-item">
                        <label>CC To</label>
                        <k-input disabled v-model="editedSetting.cc_address" />
                    </div>
                    <div class="setting-item">
                        <label>Notification(s)</label>
                        <k-multiselect :data-items="filteredNotifications"
                                       v-model="editedSetting.notis"
                                       :text-field="'displayName'"
                                       :data-item-key="'id'"
                                       :filterable="true"
                                       @filterchange="onNotiFilterChange"
                                       @change="onNotificationsChange"></k-multiselect>
                    </div>
                </div>
                <k-action-bar>
                    <k-button class="item-save__btn" @click="onSaveClicked" :disabled="saveChangesBtnDisabled">Save</k-button>
                    <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                </k-action-bar>

                <div class="component__loader-container" v-if="loading">
                    <k-loader size="large" type="infinite-spinner"></k-loader>
                </div>

                <transition name="dialogfade">
                    <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                        <p class="error-dialog__msg">{{ errorMsg }}</p>
                    </k-dialog>
                </transition>
            </k-dialog>
        </transition>

        <k-notification-group class="default__notification-container">
            <k-fade :appear="successNotification">
                <k-notification v-if="successNotification"
                                :type="{ style: 'success', icon: true }"
                                :closable="true"
                                @close="onCloseNotification('successNotification')">
                    <span>{{ notificationMsg }}</span>
                </k-notification>
            </k-fade>
        </k-notification-group>

    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { MultiSelect } from '@progress/kendo-vue-dropdowns';
    import { Input } from '@progress/kendo-vue-inputs';
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import { filterBy } from '@progress/kendo-data-query';

    import apiService from '../api/api.service.js';
    import auth from '../authConfig.js';

    import cloneDeep from 'lodash.clonedeep';
    import isEqual from 'lodash.isequal';

    export default {
        props: ['notifications', 'setting', 'showDialog'],
        emits: ['dialogclosed', 'settingupdated'],
        name: 'editSettingDialog',
        data() {
            return {
                allNotifications: [],
                allTemplatesModel: { displayName: 'All Notifications', id: Math.random().toString() },
                ccSettingModel: {
                    guid: null,
                    azure_id: null,
                    email_address: null,
                    cc_address: null,
                    template_id: null,
                    loan_number: null,
                    type: 'To'
                },
                dialogTitle: 'Edit Notification CC Setting',
                editedSetting: null,
                errorMsg: '',
                errorTitle: '',
                filteredNotifications: [],
                loading: false,
                notificationMsg: '',
                showErrorDialog: false,
                successNotification: false,
                uneditedSetting: null,
                updateParam: 'false'
            }
        },
        computed: {
            saveChangesBtnDisabled() {
                return isEqual(this.uneditedSetting, this.editedSetting) || this.editedSetting.notis?.length === 0;
            }
        },
        watch: {
            showDialog (val) {
                if (val) {
                    this.uneditedSetting = cloneDeep(this.setting);
                    this.assignNotificationTemplates();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            assignNotificationTemplates() {
                if (this.uneditedSetting.templateName === 'All Notifications') {
                    this.uneditedSetting.notis = [this.allTemplatesModel];
                } else {
                    let noti = this.notifications.find(noti => noti.displayName === this.uneditedSetting.templateName);

                    if (noti) {
                        this.uneditedSetting.notis = [noti];
                    }
                }

                this.editedSetting = cloneDeep(this.uneditedSetting);

                this.allNotifications = cloneDeep(this.notifications);
                this.allNotifications.unshift(this.allTemplatesModel);

                this.filteredNotifications = cloneDeep(this.allNotifications);
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onNotificationsChange(evt) {
                if (this.editedSetting.notis.length > 0) {
                    if (this.editedSetting.notis[this.editedSetting.notis.length - 1].displayName !== 'All Notifications') {
                        this.editedSetting.notis = this.editedSetting.notis.filter(noti => noti.displayName !== 'All Notifications');
                    } else {
                        this.editedSetting.notis = this.editedSetting.notis.filter(noti => noti.displayName === 'All Notifications');
                    }
                }
            },
            onNotiFilterChange(evt) {
                this.filteredNotifications = filterBy(this.allNotifications, evt.filter);
            },
            onRequestError(errorTitle, errorMsg) {
                this.loading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveClicked() {
                this.loading = true;

                let ccSettings = [];

                if (this.editedSetting.notis.some(noti => noti.displayName === 'All Notifications')) {
                    //one for null template swap
                    this.editedSetting.template_id = null;
                    ccSettings.push(this.editedSetting);
                } else {
                    if (this.editedSetting.notis.length === 1) {
                        //one for one template swap or null for one template swap
                        ccSettings.push({
                            guid: this.editedSetting.guid,
                            azure_id: this.editedSetting.azure_id,
                            email_address: this.editedSetting.email_address,
                            cc_address: this.editedSetting.cc_address,
                            template_id: this.editedSetting.notis[0].id,
                            loan_number: this.editedSetting.loan_number,
                            type: 'To'
                        })
                    } else {
                        //one for many template swap or null for many template swap
                        this.editedSetting.notis.forEach(noti => {
                            ccSettings.push({
                                guid: null,
                                azure_id: null,
                                email_address: this.editedSetting.email_address,
                                cc_address: this.editedSetting.cc_address,
                                template_id: noti.id,
                                loan_number: null,
                                type: 'To'
                            });
                        })
                    }

                    if (!this.uneditedSetting.template_id) {
                        this.updateParam = 'true';
                    }
                }

                this.saveCCSettings(ccSettings);
            },
            resetDefaults() {
                this.editedSetting = null;
                this.allNotifications = [];
                this.filteredNotifications = [];
                this.updateParam = 'false';
                this.loading = false;
            },
            async saveCCSettings(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpsertNotificationCCSettings(request, tokenResponse.accessToken, this.updateParam)
                    .then(res => {
                        this.loading = false;
                        this.$emit('settingupdated');

                        this.showNotification('Settings saved successfully');
                    }).catch(error => {
                        let errorTitle = 'Failed to Save CC Settings';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                }
            }
        },
        components: {
            'k-loader': Loader,
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-multiselect': MultiSelect,
            'k-input': Input,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade
        }
    }
</script>

<style>
    @import '/src/assets/css/editSettingDialog.css';
</style>