import apiService from '../api/api.service.js';

export default {
    async deleteNotificationOptOuts(subIds, token) {
        return apiService.DeleteNotificationOptOuts(subIds, token)
            .then(res => {

            }).catch(error => {
                throw error;
            });
    },
    async deletePushOptIns(subIds, token) {
        return apiService.DeletePushOptIns(subIds, token)
            .then(res => {

            }).catch(error => {
                throw error;
            });
    },
    async deleteSMSOptIns(subIds, token) {
        return apiService.DeleteSMSOptIns(subIds, token)
            .then(res => {

            }).catch(error => {
                throw error;
            });
    },
    async upsertNotificationOptOuts(request, token) {
        return apiService.UpsertNotificationOptOuts(request, token)
            .then(res => {

            }).catch(error => {
                throw error;
            });
    },
    async upsertPushOptIns(request, token) {
        return apiService.UpsertPushOptIns(request, token)
            .then(res => {

            }).catch(error => {
                throw error;
            });
    },
    async upsertSMSOptIns(request, token) {
        return apiService.UpsertSMSOptIns(request, token)
            .then(res => {

            }).catch(error => {
                throw error;
            });
    }
}