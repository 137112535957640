<template>
  <div class="processor-pipeline">
    <div class="processor-pipeline__loader-container" v-if="loading">
      <loader size="large" type="infinite-spinner"></loader>
    </div>

    <div class="admin-message" v-if="loading && isAdmin">
      <p>Loading Admin Level Data -- Please Wait</p>
    </div>

    <div class="processor-pipeline__container">
      <Grid v-if="columnsLoaded"
            :style="{ height: '100%' }"
            :data-items="gridItems"
            :data-item-key="dataItemKey"
            :resizable="true"
            :reorderable="true"
            :sortable="true"
            :pageable="gridPageable"
            :groupable="true"
            :filterable="true"
            :group="group"
            :sort="sort"
            :filter="filter"
            :take="take"
            :total="total"
            :skip="skip"
            :selectable="true"
            :selected-field="selectedField"
            :columns="gridColumns"
            :column-menu="columnMenu"
            @filterchange="filterChange"
            @columnreorder="columnReorder"
            @selectionchange="onSelectionChange"
            @headerselectionchange="onHeaderSelectionChange"
            @sortchange="sortChangeHandler"
            @datastatechange="dataStateChange"
            @groupchange="groupChangeHandler"
            @rowclick="onRowClick"
            @pagechange="pageChangeHandler">
        <template v-slot:acceptedTaskCell="{ props }">
          <td
            v-if="props.dataItem.f364"
            class="task-column" 
            style="background: #76db7a; cursor: default;" 
            title="Accepted Tasks">
              <span style="display: flex; justify-content: center; align-items: center;">{{ props.dataItem.acceptedtasks ? props.dataItem.acceptedtasks : 0  }}</span>
          </td>
        </template>
        <template v-slot:pendingTaskCell="{ props }">
          <td
            v-if="props.dataItem.f364"
            class="task-column" 
            style="background: #ede76b; cursor: default; " title="Pending Tasks">
              <span style="display: flex; justify-content: center; align-items: center;">{{ props.dataItem.pendingtasks ? props.dataItem.pendingtasks : 0  }}</span>
            </td>
          </template>
        <template v-slot:rejectedTaskCell="{ props }">
          <td
            v-if="props.dataItem.f364"
            class="task-column" 
            style="background: #ed766b; cursor: default;" 
            title="Requested Tasks">
            <span style="display: flex; justify-content: center; align-items: center;">{{ props.dataItem.rejectedtasks ?   props.dataItem.rejectedtasks : 0 }}</span>
          </td>
        </template>
        <template v-slot:addressCell="{ props }">
          <td
              style="word-wrap: break-word; font-size: .9rem;"> {{ props.dataItem.f11 }} </td>
        </template>
        <template v-slot:clientCell="{ props }">
          <td
              style="word-wrap: break-word; font-size: .9rem;">{{ props.dataItem.borrowername }}</td>
        </template>
        <template v-slot:loanCell="{ props }">
          <td
              style="word-wrap: break-word; font-size: .9rem;">{{ props.dataItem.f364 }}</td>
        </template>

        

        <toolbar>
          <div class="view-container">
            <dropdownlist :style="{ width: '230px'}"
                          :data-items='views'
                          :default-item="'All'"
                          @change="onViewChange">
            </dropdownlist>
          </div>
          <div class="search-bar-container">
            <k-input :style="{ width: '230px' }"
                     placeholder="Search.."
                     :value="searchWord"
                     @input="onFilter"
                     class="search-input">
            </k-input>
            <button class="clear-button" @click="clearSearch">Clear</button>
          </div>
        </toolbar>
      </Grid>
    </div>
  </div>
</template>

<script>
  import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
  import { DropDownList } from '@progress/kendo-vue-dropdowns';
  import { Input } from '@progress/kendo-vue-inputs';
  import { process } from "@progress/kendo-data-query";
  import { Loader } from "@progress/kendo-vue-indicators";
  import apiService from '../api/api.service.js';
  import auth from '../authConfig.js';



  export default {
    props: ['queryType', 'sessionType', 'title'],
    data() {
      return {
        acceptedTaskCell: "acceptedTaskCell",
        addressCell: 'addressCell',
        azureId: '',
        clientCell: 'clientCell',
        columnsLoaded: false,
        columnMenu: true,
        fetchDataTimeout: null,
        gridItems: [],
        gridPageable: {
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [5, 10, 20, 50],
          previousNext: true,
        },
        group: [],
        loading: false,
        loanCell: 'loanCell',
        loanType: 'Loan',
        pendingTaskCell: "pendingTaskCell",
        rejectedTaskCell: "rejectedTaskCell",
        searchWord: '',
        selectedField: "selected",
        selectedView: 'All',
        showContactFiltersDialog: false,
        skip: 0,
        sort: [],
        take: 50,
        tasksCount: [],
        total: 0,
        updatedData: [],
        views: ['Prospects', 'Active Pipeline', "Closed Loans"],
        windowWidth: window.innerWidth,
        filter: null,
        searchFilter: null,
        isAdmin: false,
      };
    },
    computed: {
      dataItemKey() {
        return this.queryType === this.loanType ? 'id' : 'contact_id';
      },
      screenWidth() {
        return window.innerWidth;
      },
      gridColumns() {
        if (this.windowWidth <= 430) {
          const columns = [
            { field: "acceptedtasks", title: "Accepted", cell: this.acceptedTaskCell, filterable: false, columnMenu: true, filter: "numeric", type: "number", width:"55px", resizable: true },
            { field: "pendingtasks", title: "Pending", cell: this.pendingTaskCell, filterable: false, columnMenu: true, filter: "numeric", type: "number", width: "55px", resizable: true },
            { field: "rejectedtasks", title: "Requested", cell: this.rejectedTaskCell, filterable: false, columnMenu: true, filter: "numeric", type: "number", width: "55px", resizable: true },
            { field: "f364", title: "Loan Number", filterable: false, sortable: true, columnMenu: true, cell: this.loanCell },
            { field: "borrowername", title: "Client", filterable: false, sortable: true, columnMenu: true, cell: this.clientCell },
            { field: "f11", title: "Address", filterable: false, sortable: true, columnMenu: true, cell: this.addressCell }
          ];
          return columns;
        } else {
          const columns = [
            { field: "acceptedtasks", title: "Accepted", cell: this.acceptedTaskCell, filterable: false, columnMenu: true, filter: "numeric", type: "number", width: "100px;" },
            { field: "pendingtasks", title: "Pending", cell: this.pendingTaskCell, filterable: false, columnMenu: true, filter: "numeric", type: "number", width: "100px;" },
            { field: "rejectedtasks", title: "Requested", cell: this.rejectedTaskCell, filterable: false, columnMenu: true, filter: "numeric", type: "number", width: "100px;" },
            { field: "f364", title: "Loan Number", filterable: true, sortable: true, columnMenu: true },
            { field: "borrowername", title: "Client", filterable: true, sortable: true, columnMenu: true },
            { field: "f11", title: "Address", filterable: true, sortable: true, columnMenu: true },
            { field: "f317", title: "Loan Officer", filterable: true, sortable: true, columnMenu: true },
            { field: "f2", title: "Loan Amount", filterable: true, sortable: true, columnMenu: true, filter: "numeric", format: "{0:c2}", type: "number" },
            { field: "f19", title: "Loan Purpose", filterable: true, sortable: true, columnMenu: true },
            { field: "f1172", title: "Loan Type", filterable: true, sortable: true, columnMenu: true },
            { field: "currentmilestone", title: "Milestone", filterable: true, sortable: true, columnMenu: true },
            { field: "orgid", title: "ORGID", filterable: true, sortable: true, columnMenu: true }
          ];
          return columns;
        }
        
        
      }
    },
    async mounted() {
      //const tokenResponse = await auth.acquireTokenResponse(); // Get Auth Token

      window.addEventListener("resize", this.getScreenWidth);

      setTimeout(() => {
        this.fetchData();
      }, 2000);
      setInterval(() => {
        this.refreshData();
      }, 300000);
    },
    methods: {

      getScreenWidth() {
        this.windowWidth = window.innerWidth;
      },
      
      async fetchData() {
        //debugger;
        this.loading = true;
        try {
          const tokenResponse = await auth.acquireTokenResponse(); // Get Auth Token
          this.azureId = localStorage.getItem('azure_id');
          if (!tokenResponse) throw new Error("Failed to acquire token response");
          //debugger;
          const response = await apiService.GetSwiftAppPipeline(this.azureId, tokenResponse.accessToken);
          //const response = await apiService.GetSwiftAppPipeline("0997affd-b6f0-4cdc-995a-0af062e328a1", tokenResponse.accessToken); //LRojas
          //const response = await apiService.GetSwiftAppPipeline("a9c6d4ee-a5eb-4573-a48b-89dcdb77b36b", tokenResponse.accessToken); //Hallstead
          const data = response.data;
          if (data && Array.isArray(data.data)) {
            this.updatedData = data.data;
            //console.log(data.data);
            this.processData();
          } else {
            console.error("No valid data received from API");
            this.updatedData = [];
          }
        }
        catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          this.loading = false;
          this.columnsLoaded = true;
        }
      },


      processData() {
        try {
          let combinedFilter = this.filter;

          // Combine the search filter with the existing filters
          if (this.searchFilter) {
            if (combinedFilter) {
              combinedFilter = {
                logic: 'and',
                filters: [combinedFilter, this.searchFilter]
              };
            } else {
              combinedFilter = this.searchFilter;
            }
          }

          // Apply the combined filter to the data
          const filteredData = process(this.filterDataByView(this.updatedData), {
            filter: combinedFilter
          }).data;

          // Update the total count to reflect the filtered data length
          this.total = filteredData.length;

          // Apply pagination and sorting to the filtered data
          const paginatedData = process(filteredData, {
            skip: this.skip,
            take: this.take,
            group: this.group,
            sort: this.sort,
          });
          
          this.gridItems = paginatedData.data || [];

        } catch (error) {
          console.error("Error processing data:", error);
          this.gridItems = [];
          this.total = 0;
        }
      },
      async refreshData() {
        console.log("Refreshing data...")
        try {
          const tokenResponse = await auth.acquireTokenResponse(); // Get Auth Token
          this.azureId = localStorage.getItem('azure_id');
          if (!tokenResponse) throw new Error("Failed to acquire token response");
          //debugger;
          const response = await apiService.GetSwiftAppPipeline(this.azureId, tokenResponse.accessToken);

          const data = response.data;
          if (data && Array.isArray(data.data)) {
            this.updatedData = data.data;
            this.processData();
          } else {
            console.error("No valid data received from API");
            this.updatedData = [];
          }
        }
        catch (error) {
          console.error("Error fetching data:", error);
        }
      },

      filterChange(e) {
        this.filter = e.filter;
        this.skip = 0;
        this.processData();
      },

      dataStateChange(e) {
        this.skip = e.skip;
        this.take = e.take;
        this.processData();
      },

      columnReorder(options) {
        this.columns = options.columns;
      },

      onSelectionChange(event) {
        const { checked, dataItem } = event;
        if (dataItem) {
          dataItem[this.selectedField] = checked;
          const itemIndex = this.updatedData.findIndex(i => i[this.dataItemKey] === dataItem[this.dataItemKey]);
          if (itemIndex >= 0) {
            this.updatedData[itemIndex][this.selectedField] = checked;
          }
          const rowElement = event.target.closest('tr');
          if (rowElement) {
            rowElement.classList.toggle('k-selected', checked);
          }
        }
      },

      onHeaderSelectionChange(event) {
        const checked = event.target.checked;
        this.gridItems = this.gridItems.map(item => {
          item[this.selectedField] = checked;
          return item;
        });
        this.updatedData = this.updatedData.map(item => {
          item[this.selectedField] = checked;
          return item;
        });
        this.$nextTick(() => {
          const inputs = document.querySelectorAll('.k-grid-content input[type="checkbox"]');
          inputs.forEach(input => {
            input.checked = checked;
            const rowElement = input.closest('tr');
            if (rowElement) {
              rowElement.classList.toggle('k-selected', checked);
            }
          });
        });
      },

      sortChangeHandler(e) {
        this.sort = e.sort;
        this.processData();
      },

      filterDataByView(data) {
        if (this.selectedView === 'All') {
          return data.filter(item => item.f1393 === 'Active Loan');
        }
        else if(this.selectedView === 'Prospects') {
          return data.filter(item => item.f1393 === 'Active Loan' && item.f3152 === '');
        }
        else if (this.selectedView === 'Active Pipeline') {
          return data.filter(item => item.f1393 === 'Active Loan' && item.loanfolder === 'My Pipeline');
        }
        else if (this.selectedView === 'Closed Loans') {
          return data.filter(item => item.f1393 === 'Loan Originated');
        }

        return data.filter(item => item.view === this.selectedView);
        
      },

      groupChangeHandler(e) {
        this.group = e.group;
        this.processData();
      },

      pageChangeHandler(e) {
        this.skip = e.page.skip;
        this.take = e.page.take;
        this.processData();
      },

      onFilter(e) {
        this.searchWord = e.target.value;

        if (this.searchWord) {
          const filters = this.columns.map(col => ({
            field: col.field,
            operator: 'contains',
            value: this.searchWord
          }));

          this.searchFilter = {
            logic: 'or',
            filters
          };
        } else {
          this.searchFilter = null;
        }

        this.skip = 0;
        this.processData();
      },

      onRowClick(e) {
        window.open(`https://swift.goldstarfinancial.com/ConsumerPortal?id=${e.dataItem.id}&swiftprocessing=true`, '_blank'); //open in new tab
      },

      onViewChange(e) {
        this.selectedView = e.value;
        this.processData();
      },

      clearSearch() {
        this.searchWord = '';
        this.searchFilter = null;
        this.processData();
      }

    },
    components: {
      'Grid': Grid,
      'dropdownlist': DropDownList,
      'k-input': Input,
      'toolbar': GridToolbar,
      'loader': Loader,
    }
  };

</script>

<style scoped>
  @import '/src/assets/css/defaultSliderInputs.css';

  .processor-pipeline {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    min-width: 1000px;
  }

  .processor-pipeline--title__container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .processor-pipeline--title {
    margin-left: 12px;
    font-size: 2.4rem;
    color: var(--primary);
    margin-bottom: 0;
    line-height: 1;
  }

  .processor-pipeline--title span {
    font-size: 1.6rem;
    margin-left: 5px;
    color: #818589;
    font-weight: normal;
  }

  .processor-pipeline--title-icon {
    font-size: 2.8rem;
    padding: 12px;
    color: var(--on-primary);
    background-color: var(--primary);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .processor-pipeline__container {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .k-grid-container {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
  }

  .processor-pipeline__loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .k-grid .k-link {
    background-color: #051d3a !important;
    color: white !important; 
  }
    
  .k-grid .k-link:hover {
    background-color: #33475b !important;
    color: white !important;
  }

  .search-bar-container {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .k-dropdownlist {
    margin-left: 10px;
    margin-right: 10px;
  }
  .search-input {
    border-color: #999 !important;
  }

  .clear-button {
    margin-left: 10px;
    padding: 6px 12px;
    border: none;
    background-color: #051d3a;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }

  .clear-button:hover {
    background-color: #d32f2f;
  }
  .task-column{
    font-size: 1.5rem;
  }


  /*Mobile styling WIP*/
  @media (max-width: 480px){
    .k-picker-md {
        font-size: 1rem;
    }
    .k-input-inner{
        font-size: 1rem;
    }
    .k-button-md.k-icon-button > .k-button-icon{
        min-width: 1rem;
        min-height: 1rem;
    }
    .k-filtercell .k-filtercell-wrapper{
        flex-flow: column-reverse !important;
    }
    [role="toolbar"]{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
      [role="toolbar"] > * {
        flex: auto;
        margin: auto;
        justify-content: center;
        align-items: center;
      }

    .search-bar-container{
        width: 100%;
    }
    .processor-pipeline{
        min-width: 0;
    }
    .k-toolbar::before{
        height: 0;
    }
    .task-column{
      font-size: 1.3rem;
    }
  }
</style>
