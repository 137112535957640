<template>
    <!--Error Dialog-->
    <transition name="dialogfade">
      <k-dialog :title="errorTitle !== '' ? errorTitle : 'Error'" class="error__dialog">
        <p class="error-dialog__msg">{{ errorMsg }}</p>

      </k-dialog>
    </transition>
</template>


<script>
  import { Dialog } from '@progress/kendo-vue-dialogs';
  import { Fade } from "@progress/kendo-vue-animation";


  export default {
    props: {
      errorTitle: {
        type: String,
        required: false
      },
      errorMsg: {
        type: String,
        required: false
      },
    },
    components: {
      'k-dialog': Dialog,
      'k-fade': Fade,
    },
    mounted() {
    },
    data() {
      return {

      }
    },
    methods: {

    },

  }
</script>
<style>
/*  @import '/src/assets/css/postClose.css';*/
  .error__dialog .k-dialog {
    border-radius: 16px !important;
    overflow: auto !important;
    min-height: unset !important;
    max-height: 98% !important;
    width: 50%;
  }



  .error__dialog .k-window-titlebar {
    padding: 20px 16px;
    background-color: var(--error) !important;
    color: var(--on-error) !important;
    border-bottom: unset !important;
    display: flex;
    align-items: center;
  }

 .error__dialog .k-window-title {
    padding: .5em 0;
    margin: -.5em 0;
    font-size: 20px;
    line-height: 1.25;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
  }

  .error__dialog .k-dialog .k-window-content {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    scrollbar-width: none !important; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

    .error__dialog .k-dialog .k-window-content::-webkit-scrollbar {
      display: none !important; /* For Chrome, Safari, and Opera */
    }

  .error__dialog .error-dialog__msg {
    margin: 10px;
    text-align: center;
    font-weight: 700;
  }

</style>
