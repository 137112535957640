const formatHelpers = {
    clearedTrackingNames: ['Waived', 'Cleared', 'Removed By Swift'],
    formatDate(stringDate) {
        if (stringDate) {
            let date = new Date(new Date(stringDate).setHours(0,0,0,0)).toString();
            return new Date(date);
        }

        return '';
    },
  formatDateTime(stringDate) {
        if (stringDate) {
            return new Date(stringDate);
        }

        return '';
    },
    getLastTracking(tracking) {
        tracking.sort((a, b) => new Date(b.date) - new Date(a.date));
        return tracking[0].status;
    },
    getTitleAndCode(title) {
        let hasNumber = /\d/;
        let result = {
            title: ''
        }

        if (title.includes('-') && hasNumber.test(title)) {
            let codeEndIndex = title.indexOf('-');

            result.title = title.substring(codeEndIndex + 1).trim();
        } else {
            result.title = title;
        }

        return result;
    }  
}

export default formatHelpers;
