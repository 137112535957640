<template>
  <div class="loans-pipeline__container">
    <processing />
  </div>
</template>

<script>
  import { Window } from '@progress/kendo-vue-dialogs';
  import { Button } from '@progress/kendo-vue-buttons';
  import auth from '../authConfig.js';
  import axios from 'axios';


  import processing from '../components/Processing.vue';
  import apiService from '../api/api.service.js';
  import formatHelpers from '../helpers/formatHelpers.js';

  export default {
    name: 'App',
    components: {

      'window': Window,
      'kbutton': Button,
      "processing": processing
    },
    data() {
      return {
        conditionsData: [],
        selectedLoan: '',
        selectedLoanBorrower: '',
        selectedLoanNumber: '',
        isLoading: true,
        visible: true,
        isError: false,
        loaderStatus: "Logging In",
        loanChange: false,
        loandata: [],
        // MSAL
        idTokenClaims: null,
      }
    },
    async created() {

      const msalAppInstance = await auth.init();

      msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {

        if (!tokenRes) {
          if (!auth.user()) {
            auth.login();
          }
        }
      });
    },
    methods: {

    }

  }
</script>

<style scoped>
  .loans-pipeline__container {
    height: 100vh;
    overflow: auto;
  }
</style>
