import * as msal from '@azure/msal-browser';

let msalApp;

export default {

  async init() {
    // Enforce Singleton
    if (msalApp) return msalApp;

    const config = {
      auth: {
        clientId: '82ee22b7-ba97-4154-ba13-7d8d12faed3f',
        authority: 'https://login.microsoftonline.com/2b7cd331-5fc1-498e-837a-460368566497',
        redirectUri: '/',
        postLogoutRedirectUri: '/'
      },
      cache: {
        cacheLocation: 'localStorage'
      },
    }

    msalApp = new msal.PublicClientApplication(config);

    return msalApp;
  },

  clientId() {
    if (!msalApp) {
      return null;
    }

    return msalApp.config.auth.clientId;
  },

  async login(scopes = []) {
    if (!msalApp) return;

    await msalApp.loginRedirect({
      scopes,
      prompt: 'select_account',
      domainHint: '2b7cd331-5fc1-498e-837a-460368566497'
    });
  },

  logout() {
    if (!msalApp) return;

    // Clear local storage
    localStorage.clear();

    // Clear session cookies (if any)
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    });

    msalApp.logoutRedirect();
  },

  user() {
    if (!msalApp) return null;

    const currentAccounts = msalApp.getAllAccounts();
    if (!currentAccounts || currentAccounts.length === 0) {
      // No user signed in
      return null;
    } else if (currentAccounts.length > 1) {
      return currentAccounts[0];
    } else {
      return currentAccounts[0];
    }
  },

  async acquireTokenResponse(scopes = ['api://82ee22b7-ba97-4154-ba13-7d8d12faed3f/Files.Read']) {
    if (!msalApp) return null;

    const accessTokenRequest = {
      scopes,
      account: this.user()
    }

    let tokenRes;
    try {
      tokenRes = await msalApp.acquireTokenSilent(accessTokenRequest);
    } catch (err) {
      tokenRes = await msalApp.acquireTokenRedirect(accessTokenRequest);
    }

    if (!tokenRes.accessToken) {
      throw new Error("accessToken missing in response");
    }

    // Store the azureId in localStorage
    const homeAccountId = tokenRes.account.homeAccountId;
    const azureId = homeAccountId.split('.')[0]; // Take only the first part
    localStorage.setItem('azure_id', azureId);

    return tokenRes;
  },

  clearLocal() {
    if (msalApp) {
      for (let entry of Object.entries(localStorage)) {
        let key = entry[0];
        if (key.includes('login.windows')) {
          localStorage.removeItem(key);
        }
      }
    }
  }
};
