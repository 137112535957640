<template>
    <div class="notification-preview">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" :title="notification.title" class="default__dialog notification-preview-dialog" @close="onCloseDialog">
                <div class="add-notification-container">
                    <k-button class="add-noti-btn" @click="onAddNotification">Add Notification</k-button>
                </div>
                <div class="preview-container" v-html="notification.html"></div>
            </k-dialog>
        </transition>
    </div> 
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'NotificationPreviewDialog',
        props: ['notification', 'showDialog'],
        emits: ['dialogclosed', 'notificationadded'],
        data() {
            return {
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                }
            }
        },
        methods: {
            onAddNotification() {
                this.$emit('notificationadded');
                this.onCloseDialog();
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-button': Button
        }
    }
</script>

<style>
    @import '/src/assets/css/notificationPreviewDialog.css';
</style>